<template>
  <div :class="['homePage', { 'for-search': forSearch }]">
    <div class="quality" v-if="!forSearch">
      <div class="main sc-width">
        <div class="title">
          Discover the products you want here... and more!
        </div>
        <div class="featured-storefronts hot-products">
          <div class="storefronts-title">
            <div class="icon">
              <svg
                width="3"
                height="20"
                viewBox="0 0 3 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="1.5"
                  y1="1.5"
                  x2="1.5"
                  y2="18.5"
                  stroke="#F57F45"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div class="desc">
              Hot Products
            </div>
          </div>
          <div class="content">
            <div class="swiper-container swiper">
              <div class="swiper-wrapper product-show">
                <div
                  class="item swiper-slide"
                  v-for="(item, index) in hotProduct"
                  :key="index"
                >
                  <div class="info">
                    <div
                      class="name search-button"
                      @click="goUrl('/products/' + item.id)"
                    >
                      {{ item.name }}
                    </div>
                    <div class="company-info search-button">
                      <div
                        class="company-logo"
                        @click="goUrl('/company/' + item.company_id)"
                      >
                        <img :src="item.company_logo" alt="" />
                      </div>
                      <div
                        class="company-name search-button"
                        @click="goUrl('/company/' + item.company_id)"
                        :title="item.company_name"
                      >
                        {{ item.company_name }}
                      </div>
                    </div>
                  </div>
                  <div class="back" v-if="index % 5 == 0">
                    <svg
                      width="137"
                      height="228"
                      viewBox="0 0 137 228"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_f_482_4302)">
                        <circle
                          cx="113.905"
                          cy="123.151"
                          r="72.444"
                          fill="url(#paint0_linear_482_4302)"
                        />
                      </g>
                      <g filter="url(#filter1_f_482_4302)">
                        <path
                          d="M137.588 31.2021L200.729 140.565H74.4476L137.588 31.2021Z"
                          fill="url(#paint1_linear_482_4302)"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_482_4302"
                          x="0.720192"
                          y="9.96629"
                          width="226.368"
                          height="226.369"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="20.3704"
                            result="effect1_foregroundBlur_482_4302"
                          />
                        </filter>
                        <filter
                          id="filter1_f_482_4302"
                          x="43.8937"
                          y="0.646591"
                          width="187.392"
                          height="170.473"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="15.2778"
                            result="effect1_foregroundBlur_482_4302"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear_482_4302"
                          x1="56.4373"
                          y1="60.6913"
                          x2="157.557"
                          y2="195.595"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#6100FF" />
                          <stop
                            offset="1"
                            stop-color="#00FFFF"
                            stop-opacity="0"
                          />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_482_4302"
                          x1="137.588"
                          y1="31.2021"
                          x2="137.588"
                          y2="177.019"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#0075FF" />
                          <stop
                            offset="1"
                            stop-color="#00A3FF"
                            stop-opacity="0"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div class="back back-1" v-if="index % 5 == 1">
                    <svg
                      width="140"
                      height="231"
                      viewBox="0 0 140 231"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_f_482_4328)">
                        <circle
                          cx="113.943"
                          cy="122.595"
                          r="72.8605"
                          fill="url(#paint0_linear_482_4328)"
                        />
                      </g>
                      <g filter="url(#filter1_f_482_4328)">
                        <path
                          d="M137.761 30.1182L201.265 140.109H74.2576L137.761 30.1182Z"
                          fill="url(#paint1_linear_482_4328)"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_482_4328"
                          x="0.10709"
                          y="8.75943"
                          width="227.673"
                          height="227.671"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="20.4875"
                            result="effect1_foregroundBlur_482_4328"
                          />
                        </filter>
                        <filter
                          id="filter1_f_482_4328"
                          x="43.5266"
                          y="-0.613041"
                          width="188.47"
                          height="171.454"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="15.3656"
                            result="effect1_foregroundBlur_482_4328"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear_482_4328"
                          x1="56.1445"
                          y1="59.776"
                          x2="157.846"
                          y2="195.455"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#8FFF00" />
                          <stop
                            offset="1"
                            stop-color="#00FF75"
                            stop-opacity="0"
                          />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_482_4328"
                          x1="137.761"
                          y1="30.1182"
                          x2="137.761"
                          y2="176.773"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#61FF00" />
                          <stop
                            offset="1"
                            stop-color="#00FF29"
                            stop-opacity="0"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div class="back back-2" v-if="index % 5 == 2">
                    <svg
                      width="145"
                      height="230"
                      viewBox="0 0 145 230"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_f_482_4392)">
                        <circle
                          cx="113.964"
                          cy="119.448"
                          r="72.444"
                          fill="url(#paint0_linear_482_4392)"
                        />
                      </g>
                      <g filter="url(#filter1_f_482_4392)">
                        <path
                          d="M137.647 27.499L200.787 136.862H74.5062L137.647 27.499Z"
                          fill="url(#paint1_linear_482_4392)"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_482_4392"
                          x="0.778786"
                          y="6.26316"
                          width="226.368"
                          height="226.369"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="20.3704"
                            result="effect1_foregroundBlur_482_4392"
                          />
                        </filter>
                        <filter
                          id="filter1_f_482_4392"
                          x="43.9523"
                          y="-3.05653"
                          width="187.392"
                          height="170.473"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="15.2778"
                            result="effect1_foregroundBlur_482_4392"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear_482_4392"
                          x1="56.4959"
                          y1="56.9882"
                          x2="157.616"
                          y2="191.892"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF5C00" />
                          <stop
                            offset="1"
                            stop-color="#FFB800"
                            stop-opacity="0"
                          />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_482_4392"
                          x1="137.647"
                          y1="27.499"
                          x2="137.647"
                          y2="173.316"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF5C00" />
                          <stop
                            offset="1"
                            stop-color="#FFB800"
                            stop-opacity="0"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div class="back back-3" v-if="index % 5 == 3">
                    <svg
                      width="145"
                      height="230"
                      viewBox="0 0 145 230"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_f_482_4407)">
                        <circle
                          cx="114.167"
                          cy="122.299"
                          r="72.444"
                          fill="url(#paint0_linear_482_4407)"
                        />
                      </g>
                      <g filter="url(#filter1_f_482_4407)">
                        <path
                          d="M137.85 30.3496L200.99 139.712H74.7093L137.85 30.3496Z"
                          fill="url(#paint1_linear_482_4407)"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_482_4407"
                          x="0.981911"
                          y="9.11375"
                          width="226.368"
                          height="226.369"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="20.3704"
                            result="effect1_foregroundBlur_482_4407"
                          />
                        </filter>
                        <filter
                          id="filter1_f_482_4407"
                          x="44.1554"
                          y="-0.205948"
                          width="187.392"
                          height="170.473"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="15.2778"
                            result="effect1_foregroundBlur_482_4407"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear_482_4407"
                          x1="56.6991"
                          y1="59.8388"
                          x2="157.819"
                          y2="194.743"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#EB00FF" />
                          <stop
                            offset="1"
                            stop-color="#FF00A8"
                            stop-opacity="0"
                          />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_482_4407"
                          x1="137.85"
                          y1="30.3496"
                          x2="137.85"
                          y2="176.166"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF00F5" />
                          <stop
                            offset="1"
                            stop-color="#FF00B8"
                            stop-opacity="0"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div class="back back-4" v-if="index % 5 == 4">
                    <svg
                      width="140"
                      height="230"
                      viewBox="0 0 140 230"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_f_482_4375)">
                        <circle
                          cx="113.241"
                          cy="117.411"
                          r="72.444"
                          fill="url(#paint0_linear_482_4375)"
                        />
                      </g>
                      <g filter="url(#filter1_f_482_4375)">
                        <path
                          d="M136.924 25.4629L200.065 134.826H73.7835L136.924 25.4629Z"
                          fill="url(#paint1_linear_482_4375)"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_482_4375"
                          x="0.0561295"
                          y="4.22605"
                          width="226.368"
                          height="226.369"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="20.3704"
                            result="effect1_foregroundBlur_482_4375"
                          />
                        </filter>
                        <filter
                          id="filter1_f_482_4375"
                          x="43.2296"
                          y="-5.09267"
                          width="187.392"
                          height="170.473"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feGaussianBlur
                            stdDeviation="15.2778"
                            result="effect1_foregroundBlur_482_4375"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear_482_4375"
                          x1="55.7733"
                          y1="54.9511"
                          x2="156.893"
                          y2="189.855"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF008A" />
                          <stop
                            offset="1"
                            stop-color="#FF004D"
                            stop-opacity="0"
                          />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_482_4375"
                          x1="136.924"
                          y1="25.4629"
                          x2="136.924"
                          y2="171.28"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF008A" />
                          <stop
                            offset="1"
                            stop-color="#FF006B"
                            stop-opacity="0"
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="exhibitor-list sc-width">
      <div class="list-title font-medium">List of Products</div>
      <div class="divided"></div>
      <div class="search-wrap" style="border:none">
        <div
          :class="[
            'search-box',
            'search-box2',
            { 'search-mobile': mobileWidth }
          ]"
        >
          <div class="searchWrap category-wrap">
            <div class="title category-title">Category</div>
            <Select
              v-model="filter.precise_product_type"
              placeholder="select one category"
              style="width:200px"
            >
              <OptionGroup label="Botanical">
                <Option value="Botanical">Botanical</Option>
              </OptionGroup>

              <OptionGroup label="Non-Botanical">
                <Option value="">All Categories</Option>
                <Option
                  v-for="item in cateList['Non-Botanical']"
                  :key="item"
                  :value="item"
                  >{{ item }}</Option
                >
              </OptionGroup>
            </Select>
          </div>

          <div class="searchWrap search-type-wrap">
            <div class="title type-title">Type</div>
            <Select
              filterable
              v-model="filter.tag"
              placeholder="select one type"
              style="width:200px"
            >
              <Option value="">All Types</Option>
              <Option
                filterable
                v-for="item in categories"
                :value="item.name"
                :key="item.id"
                >{{ item.name }}</Option
              >
            </Select>
          </div>

          <div class="searchWrap country-wrap" v-if="!mobileWidth">
            <div class="title country-title" v-if="!mediumWidth">Country</div>
            <div class="title country-title" v-else>Country</div>
            <Select
              filterable
              v-model="filter.country"
              placeholder="select one country"
            >
              <Option value="">All Countries</Option>
              <Option
                v-for="item in countryList"
                :value="item.id"
                :key="item.id"
                >{{ item.name }}</Option
              >
            </Select>
          </div>
          <div class="searchWrap country-wrap" v-if="!mobileWidth">
            <div class="title country-title" v-if="!mediumWidth">Warehouse</div>
            <div class="title country-title" v-else>Warehouse</div>
            <Select
              filterable
              v-model="filter.warehouse_country"
              placeholder="select one country"
            >
              <Option value="">All Countries</Option>
              <Option
                v-for="item in countryList"
                :value="item.id"
                :key="item.id"
                >{{ item.name }}</Option
              >
            </Select>
          </div>
          <div
            v-if="mobileWidth"
            class="searchWrap"
            style="flex:0 0 100%;text-align:center;margin-top:15px"
          >
            <Button
              shape="circle"
              @click="handleSearch"
              style="width:98%;margin-left:0"
              >Apply Filter</Button
            >
          </div>
          <div
            v-else
            class=" filter font-medium"
            style="text-align: center;"
            @click="handleSearch"
          >
            Apply Filter
          </div>
        </div>
      </div>

      <div class="data-field">
        <div class="list sc-width">
          <div class="popular">
            <div class="label">Popular Search Terms</div>
            <div
              class="word"
              @click="chooseWord(item.key_word)"
              v-for="(item, index) in words"
              :key="index"
            >
              {{ item.key_word }}
            </div>
          </div>
          <div class="load-data" v-if="loading">
            <div class="pic"><img src="@/assets/loading.gif" /></div>
          </div>
          <div class="list-data" v-else-if="list.length > 0">
            <div
              class="item"
              :key="product.id"
              v-for="(product, index) in list"
            >
              <div class="top" @click="viewProduct(product)">
                <img :src="product.images[0].url" />
              </div>
              <div class="bottom">
                <div class="logo">
                  <div class="back" @click="viewProduct(product)">
                    <img :src="product.company.avatar.url" />
                  </div>
                  <div class="favorite" @click="favorite(index)">
                    <template v-if="!product.is_favorite">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.83464 2.5C3.5338 2.5 1.66797 4.34667 1.66797 6.625C1.66797 8.46417 2.39714 12.8292 9.57464 17.2417C9.7032 17.3199 9.8508 17.3613 10.0013 17.3613C10.1518 17.3613 10.2994 17.3199 10.428 17.2417C17.6055 12.8292 18.3346 8.46417 18.3346 6.625C18.3346 4.34667 16.4688 2.5 14.168 2.5C11.8671 2.5 10.0013 5 10.0013 5C10.0013 5 8.13547 2.5 5.83464 2.5Z"
                          stroke="#BABABA"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </template>
                    <template v-else>
                      <svg
                        width="21"
                        height="19"
                        viewBox="0 0 21 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.7578 3.7172C19.4437 2.98979 18.9907 2.33062 18.4242 1.77658C17.8573 1.22089 17.189 0.779292 16.4555 0.475798C15.6949 0.159844 14.8791 -0.00187919 14.0555 1.64739e-05C12.9 1.64739e-05 11.7727 0.316423 10.793 0.914079C10.5586 1.05705 10.3359 1.21408 10.125 1.38517C9.91406 1.21408 9.69141 1.05705 9.45703 0.914079C8.47734 0.316423 7.35 1.64739e-05 6.19453 1.64739e-05C5.3625 1.64739e-05 4.55625 0.159392 3.79453 0.475798C3.05859 0.780485 2.39531 1.21877 1.82578 1.77658C1.25859 2.32999 0.805495 2.98932 0.492188 3.7172C0.166406 4.47424 0 5.27814 0 6.10548C0 6.88595 0.159375 7.69923 0.475781 8.52658C0.740625 9.21799 1.12031 9.93517 1.60547 10.6594C2.37422 11.8055 3.43125 13.0008 4.74375 14.2125C6.91875 16.2211 9.07266 17.6086 9.16406 17.6649L9.71953 18.0211C9.96563 18.1781 10.282 18.1781 10.5281 18.0211L11.0836 17.6649C11.175 17.6063 13.3266 16.2211 15.5039 14.2125C16.8164 13.0008 17.8734 11.8055 18.6422 10.6594C19.1273 9.93517 19.5094 9.21799 19.7719 8.52658C20.0883 7.69923 20.2477 6.88595 20.2477 6.10548C20.25 5.27814 20.0836 4.47424 19.7578 3.7172Z"
                          fill="#F24E1E"
                        />
                      </svg>
                    </template>
                  </div>
                </div>
                <div
                  class="title font-medium"
                  :title="product.marketing_name"
                  @click="viewProduct(product)"
                >
                  {{ product.marketing_name }}
                </div>
                <div class="company" @click="viewProduct(product)">
                  {{ product.company.company_name }}
                </div>
                <!--                <div class="label">PRODUCT CATEGORY</div>-->
                <div class="value" @click="viewProduct(product)">
                  {{ product.product_type }}
                </div>
                <!--                <div class="label">ORIGIN</div>-->
                <!--                <div class="value">China</div>-->
                <div
                  class="tag"
                  :style="{ background: product.tags[0].color }"
                  @click="viewProduct(product)"
                >
                  {{ product.tags[0].name }}
                </div>
              </div>
            </div>
          </div>

          <div class="no-data" v-else>
            <div class="pic"><img src="@/assets/no-data.png" /></div>
            <div class="desc">No Data</div>
          </div>

          <div class="page sc-width">
            <Page
              transfer
              :current="page"
              :page-size="limit"
              :total="total"
              show-elevator
              show-total
              @on-change="pageChange"
              @on-page-size-change="pageSizeChange"
            />
          </div>
        </div>
        <Ads adsnum="0"></Ads>
        <PopLogin ref="popLogin"></PopLogin>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
// import { mapState } from "vuex";
// import { uniq, trim } from "lodash";
import Ads from "@/components/ads";
import "swiper/swiper-bundle.min.css";
import PopLogin from "@/components/popLogin";
import Swiper, { Autoplay } from "swiper";
Swiper.use([Autoplay]);

import { mapState } from "vuex";

const {
  _queryAllProductList,
  _country,
  _queryProductRelatedInfo,
  // _createSourcing,
  _searchPopular,
  _queryHotProduct,
  _createFavorite,
  _deleteFavorite
} = api;
export default {
  computed: {
    ...mapState("user", ["token", "role"])
  },
  components: {
    Ads,
    PopLogin
  },
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      mediumWidth: window.innerWidth < 1600,
      filter: {
        search: ""
      },
      curBar: "",
      page: +sessionStorage.getItem("prosPage") || 1,
      limit: 12, // must in 5-20
      total: 0,
      loading: false,
      list: [],
      hotProduct: [],
      sourcingFirst: "",
      sourcingSecond: "",
      sourcingThird: "",
      sourcingfourth: "",
      swiper: "",
      mloading: false,
      countryList: [],
      cateList: [],
      categories: [],
      inquireForm: {},
      words: [],
      forSearch: false
    };
  },
  created() {
    this.filter.search = this.$route.query.s;
    if (
      "" !== this.$route.query.q &&
      typeof this.$route.query.q !== "undefined"
    ) {
      this.forSearch = true;
    }
  },
  mounted() {
    _searchPopular().then(response => {
      const { data } = response;
      this.words = data;
    });
    _country().then(response => {
      const { data } = response;
      this.countryList = data;
    });
    _queryProductRelatedInfo().then(response => {
      const { data } = response;
      this.cateList = data.product_type;
      this.categories = data.product_tag;
    });
    this.filter.search = this.$route.query.q;
    // if('' !== this.filter.search){
    //   this.forSearch = true
    // }
    this.queryAllProductList();
    this.queryHotProductList();
    this.$nextTick(() => {
      // this.initSwiper()
    });
  },
  methods: {
    goUrl(url) {
      this.$router.push(url);
    },
    favorite(index) {
      if (this.role === "") {
        this.$refs.popLogin.showLogin = true;
      } else {
        if (this.list[index].is_favorite) {
          this.list[index].is_favorite = false;
          _deleteFavorite({ id: this.list[index].id, type: 0 });
        } else {
          this.list[index].is_favorite = true;
          _createFavorite({ related_id: this.list[index].id, type: 0 });
        }
      }
    },
    chooseWord(w) {
      this.filter.search = w;
      this.handleSearch();
    },
    sendInquiry() {
      if ("" === this.role) {
        this.$router.push("/user-action/login");
      } else {
        this.$router.push({
          path: "/sourcing-inquiry",
          query: {
            a: this.sourcingFirst,
            b: this.sourcingSecond,
            c: this.sourcingThird,
            d: this.sourcingfourth
          }
        });
      }
    },
    pageChange(page) {
      this.page = page;
      this.queryAllProductList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryAllProductList();
    },
    initSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        loop: true,
        direction: "horizontal",
        slidesPerView: 6,
        spaceBetween: 40,
        // slidesPerColumn: 2,
        // slidesPerColumnFill: "row",
        autoplayDisableOnInteraction: true,
        observer: true,
        observeParents: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
    },
    prev() {
      this.swiper.slidePrev();
    },
    next() {
      this.swiper.slideNext();
    },
    homeWithRedirect() {
      this.$router.push(`/index`);
    },
    dataSearchWithRedirect() {
      this.$router.push(`/data-search`);
    },
    productsWithRedirect() {
      this.$router.push(`/products`);
    },
    exhibitorsWithRedirect() {
      this.$router.push(`/exhibitors`);
    },
    viewProduct(item) {
      if (item.redirect_url) {
        window.open(item.redirect_url);
      } else if ("Basic" === item.level) {
        this.$router.push(`/company/` + item.company_id);
      } else {
        this.$router.push(`/products/` + item.id);
      }
    },
    handleSearch() {
      this.page = 1;
      this.queryAllProductList();
    },
    queryAllProductList() {
      this.loading = true;
      if (this.screenWidth) this.limit = 15;
      else this.limit = 12;

      _queryAllProductList({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    queryHotProductList() {
      this.filter.search = this.$route.query.s;
      if (this.screenWidth) this.limit = 15;
      else this.limit = 12;

      _queryHotProduct()
        .then(response => {
          const { data } = response;
          for (let i = 0; i < data.length; i++) {
            this.hotProduct.push({
              img: data[i]["images"][0].url,
              name: data[i].marketing_name,
              id: data[i].id,
              level: data[i]["company"].level,
              company_id: data[i]["company"].id,
              redirect: data[i].redirect_url,
              company_name: data[i]["company"]["company_name"],
              company_logo: data[i]["company"]["avatar"]["url"]
            });
            // console.log(this.hotProduct)
          }
          this.$nextTick(function() {
            this.initSwiper();
          });
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          // this.loading = false;
        });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
  .list-data {
    .item {
      width: 357px;
      height: 480px;
      margin-right: 15px;
      .top {
        width: 357px;
        height: 231px;
        img {
          width: 357px;
          height: 231px;
        }
      }
      .bottom {
        .logo {
          width: 146px;
          height: 146px;
          top: -55px;
          .favorite {
            position: relative;
            left: 305px;
            bottom: 70px;
          }
        }

        .title {
          top: -100px;
        }
        .company {
          top: -90px;
        }
        .label {
          top: -80px;
        }
        .value {
          top: -70px;
        }
        .tag {
          left: 117px;
          bottom: 25px;
        }
      }
    }
  }
}
@media (max-width: 1600px) and (min-width: 1400px) {
  .swiper-button-prev {
    left: 35px !important;
  }
  .swiper-button-next {
    right: 41px !important;
  }
  .search-wrap {
    .search-box {
      .searchWrap {
        .ivu-select {
          width: 150px;
        }
        .type-title {
          width: 50px !important;
        }
      }
    }
    .country-wrap {
      margin-right: 20px !important;
      .country-title {
        width: 90px !important;
      }
    }
    .filter {
      /*padding-left:70px !important;*/
    }
  }

  .list-data {
    .item {
      width: 287px;
      height: 415px;
      margin-right: 10px;
      .top {
        width: 287px;
        height: 200px;
        img {
          width: 290px;
          height: 200px;
        }
      }
      .bottom {
        .logo {
          width: 140px;
          height: 140px;
          top: -80px;
        }

        .title {
          top: -110px;
          width: 260px;
        }
        .company {
          top: -100px;
        }
        .label {
          top: -90px;
        }
        .value {
          top: -80px;
        }
        .tag {
          left: 77px;
          bottom: 42px;
        }
      }
    }
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .swiper-button-prev {
    left: -22px !important;
  }
  .swiper-button-next {
    right: -15px !important;
  }
  .search-wrap {
    .search-box {
      .searchWrap {
        /*display: block !important;*/
        .ivu-select {
          width: 130px;
        }
        .type-title {
          width: 50px !important;
        }
      }
      .category-wrap {
        width: 200px;
      }
      .country-wrap {
        width: 220px !important;
        margin-right: 20px !important;
        .country-title {
          width: 90px !important;
        }
      }
      .filter {
        padding-left: 11px !important;
        width: 150px;
        display: block !important;
        flex: 1 !important;
      }
    }
    .country-wrap {
      margin-right: 30px !important;
      .country-title {
        width: 180px !important;
      }
    }
  }
  .list-data {
    .item {
      width: 355px;
      height: 480px;
      margin-right: 8px;
      .top {
        width: 355px;
        height: 231px;
        img {
          width: 357px;
          height: 231px;
        }
      }
      .bottom {
        .logo {
          width: 146px;
          height: 146px;
          top: -55px;
        }

        .title {
          top: -100px;
          width: 320px;
        }
        .company {
          top: -90px;
        }
        .label {
          top: -80px;
        }
        .value {
          top: -70px;
        }
        .tag {
          left: 117px;
          bottom: 25px;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .list-data {
    .item {
      width: 357px;
      height: 480px;
      .top {
        width: 357px;
        height: 231px;
        img {
          width: 357px;
          height: 231px;
        }
      }
      .bottom {
        .logo {
          width: 146px;
          height: 146px;
          top: -55px;
        }

        .title {
          top: -100px;
        }
        .company {
          top: -90px;
        }
        .label {
          top: -80px;
        }
        .value {
          top: -70px;
        }
        .tag {
          left: 117px;
          bottom: 25px;
        }
      }
    }
  }
}
.homePage {
  background: #ffffff;
  margin-top: 153px;
}
.for-search {
  /*margin-top:208px*/
  padding-top: 20px;
  .exhibitor-list {
    margin-top: 20px !important;
    .list-title {
      margin-top: 0px !important;
    }
  }
}
.navs {
  display: flex;
  height: 84px;
  /*width:1500px;*/
  margin: 0 auto;
  align-items: center;
  .nav {
    margin: 20px;
    font-size: 20px;
    font-weight: 700;
    color: #53575c;
    line-height: 17px;
    cursor: pointer;
  }
  .home {
    margin-left: 0px;
  }
  .active {
    color: #ef842d;
    cursor: auto;
  }
}
.search-wrap {
  padding: 1px 0;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  height: 35px;
  overflow: hidden;
  // width: 45%;
  margin: 15px 20px;

  &.search-box2 {
    height: 60px;
    border-radius: 0;

    .searchWrap {
      /*flex: 0 0 20%;*/
      margin-right: 10px;
      display: flex;

      .title {
        padding-bottom: 4px;
        padding-bottom: 4px;
        font-weight: 400;
        width: 250px;
        padding-top: 5px;
        font-size: 18px;
      }
      .country-title {
        width: 140px;
      }
      .category-title {
        width: 85px;
      }
      .type-title {
        width: 80px;
      }
      .ivu-select {
        margin-top: 3px;
      }
    }
    .country-wrap {
      margin-right: 20px;
      width: 330px;
    }
    .search-type-wrap {
      width: 200px;
    }
  }
  .filter {
    width: 206px;
    height: 41px;
    background: #ef842d;
    border-radius: 5px;
    padding: 12px 0 0 0px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 17px;
    cursor: pointer;
    /*margin:20px 0 0 60px;*/
  }
  &.search-mobile {
    flex-wrap: wrap;
    height: auto;

    .searchWrap {
      flex: 0 0 48%;
      margin: 0 1%;
    }
  }
}
.search-bar {
  padding: 2px 20px;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  background-color: #fff;
  li {
    display: inline-block;
    padding: 2px;
    margin: 4px 6px;
    cursor: pointer;
    color: #bbb;
    &.cur {
      color: @primary-color;
      font-weight: bold;
    }
  }
}
.quality {
  max-width: 1920px;
  margin: 0 auto;
  height: 530px;
  background: #2b2c3f;
  .main {
    margin: 0 auto;
    .title {
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      /* identical to box height */

      display: flex;
      align-items: center;

      /* White */

      color: #ffffff;
      padding-top: 78px;
    }
    .featured-storefronts {
      margin-top: 46px;
      .storefronts-title {
        display: flex;
        margin-bottom: 32px;
        .icon {
          margin-right: 16px;
          position: relative;
          top: 6px;
        }
        .desc {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          display: flex;
          align-items: center;

          /* White */

          color: #ffffff;
        }
      }
      .content {
        height: 176px;
        .swiper-slide {
          /*height: 180px !important;*/
          /*width: 180px !important;*/
        }
        .recommend {
          width: 176px;
          height: 176px;
          background: #ffffff;
          border-radius: 8px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .product-show {
          .item {
            height: 240px;
            width: 220px !important;
            background: #fff;
            padding: 20px 0 20px 15px;
            .info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
            }
            .name {
              font-weight: 600;
              font-size: 17px;
              text-align: left;
              width: 115px;
              color: #000;
              z-index: 999;
            }
            .company-info {
              display: flex;
              .company-logo {
                img {
                  width: 35px;
                  height: 35px;
                }
              }
              .company-name {
                font-weight: 500;
                font-size: 12px;
                display: flex;
                /*justify-content: center;*/
                align-items: center;
                margin-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 135px;
                z-index: 999;
              }
            }
            .back {
              position: relative;
              bottom: 208px;
              left: 33px;
            }
          }
        }
      }
    }
  }
}
.data-field {
  width: 100%;
  background: #f2f2f2;
  .list {
    .popular {
      display: flex;
      position: relative;
      top: 32px;
      bottom: 42px;
      margin-bottom: 42px;
      .label {
        font-size: 16px;
        font-weight: 400;
        color: #252729;

        /*line-height: 17px;*/
        margin: 5px 26px 0 0;
      }
      .word {
        height: 35px;
        border: 1px solid #ef842d;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        color: #ef842d;
        margin-left: 10px;
        padding: 5px 19px 0 19px;
      }
    }
  }
}
.exhibitor-list {
  margin: 0 auto;
  .divided {
    height: 1px;
    background: #cccccc;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 36px;
  }
  .list-title {
    font-size: 36px;
    font-weight: 500;
    color: #252729;
    line-height: 17px;
    margin: 60px 0 36px 0;
  }
}
.list {
  /*width: 1500px;*/
  margin: 0 auto;
  .list-data {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
    margin-left: 10px;
    .item {
      background: #ffffff;
      border-radius: 5px;
      margin-top: 24px;
      cursor: pointer;
      .top {
        border-radius: 5px;
      }
      .bottom {
        .logo {
          /*background: #FFFFFF;*/
          position: relative;
          left: 10px;
          .back {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            text-align: center;
            vertical-align: middle;
            background: #ffffff;
            padding-top: 10px;
            img {
              width: 70px;
              height: 70px;
            }
          }
        }
        .title {
          font-size: 20px;
          font-weight: 500;
          color: #252729;
          position: relative;
          left: 22px;
          max-width: 330px;
          max-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .company {
          font-size: 16px;
          font-weight: 400;
          color: #808080;
          position: relative;
          left: 22px;
        }
        .label {
          font-size: 12px;
          font-weight: 500;
          color: #808080;
          position: relative;
          left: 22px;
        }
        .value {
          /*width: 44px;*/
          height: 9px;
          font-size: 16px;
          font-weight: 400;
          color: #808080;
          position: relative;
          left: 22px;
        }
        .tag {
          width: 122px;
          height: 32px;
          background: #ef842d;
          border-radius: 5px;
          position: relative;
          text-align: center;
          display: table-cell;
          vertical-align: middle;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
.page {
  display: flex;
  justify-content: center;
  margin: 55px auto;
}
.sourcing {
  position: relative;
  top: 30px;
  margin-bottom: 70px;
  .title {
    font-size: 36px;

    font-weight: 500;
    color: #252729;
    line-height: 17px;
    margin-bottom: 28px;
  }
  .border {
    width: 1500px;
    height: 1px;
    background: #8a9199;
    margin: 0 auto;
  }
  .out {
    margin-top: 60px;
    .content {
      width: 731px;
      height: 394px;
      background: #ffffff;
      box-shadow: 0px 0px 0px 6px rgba(239, 132, 45, 0.3);
      border-radius: 5px;
      padding-top: 1px;
      .looking {
        height: 24px;
        font-size: 24px;

        font-weight: 500;
        color: #f08622;
        margin: 41px 0 35px 32px;
      }
      .form {
        .item {
          display: inline-block;
          margin: 0 0px 26px 35px;
          .left {
            margin-right: 45px;
          }
          .label {
            height: 12px;
            font-size: 16px;

            font-weight: 500;
            color: #53575c;
            margin-bottom: 15px;
          }
          .value {
            input {
              width: 303px;
              height: 34px;
              background: #fafafa;
              border: 1px solid #8a9199;
              border-radius: 5px;
              outline: none;
              padding-left: 5px;
              font-size: 16px;
            }
          }
        }
        .send {
          width: 328px;
          height: 43px;
          background: linear-gradient(90deg, #f5a210, #ef8126);
          border-radius: 5px;
          font-size: 22px;

          font-weight: 700;
          color: #ffffff;
          margin: 20px auto;
          text-align: center;
          padding-top: 6px;
          cursor: pointer;
        }
      }
    }
  }
}

.swiper-container {
  /*width: 1050px;*/
  margin: 0 auto;
  margin-bottom: 20px;
  .swiper-slide {
    /*width:209px !important;*/
    height: 238px;
    overflow: hidden;
    border-radius: 6px;
    // border: 1px solid #f2f2f2;
    text-align: center;
    .hot-item {
      width: 209px;
      height: 220px;
      padding-top: 22px;
      background: #ffffff;
      .img {
        width: 188px;
        height: 125px;
        padding-right: 10px;
        padding-left: 10px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
      }
      .name {
        padding-top: 30px;
        padding-left: 20px;
        font-size: 16px;

        font-weight: 400;
        color: #53575c;
        line-height: 13px;
        float: left;
      }
    }
  }
}
.swiper-button-prev {
  position: relative;
  left: 150px;
  right: auto;
  top: 250px;
  color: #252729;
  z-index: auto;
}
.swiper-button-next {
  position: relative;
  right: 150px;
  top: 250px;
  color: #252729;
  z-index: auto;
}
.no-data {
  text-align: center;
  margin-top: 30px;
}
.load-data {
  margin-top: 30px;
  text-align: center;
  .pic {
    img {
      width: 60px;
      height: 60px;
    }
  }
}
</style>
<style lang="less">
.ivu-select-item {
  font-size: 16px !important;
}
.ivu-select-input {
  font-size: 16px;
}
</style>
