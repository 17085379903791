<template>
  <div>
    <div
      class="advImg sc-width"
      v-if="adsLists.length"
      @click="hisAds(adsLists[adsnum].id)"
    >
      <a :href="adsLists[adsnum].jump_url" target="_blank"
        ><img alt="" :src="adsLists[adsnum].ads_url"
      /></a>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _queryAds, _hisAds } = api;

export default {
  props: ["adsnum"],
  data() {
    return {
      adsLists: []
    };
  },
  mounted() {
    _queryAds().then(response => {
      const { data } = response;
      this.adsLists = data;
    });
  },
  methods: {
    hisAds(aid) {
      _hisAds({ ads_id: aid });
    }
  }
};
</script>
<style lang="less">
.advImg {
  margin: 0 auto;
  padding: 20px 0;
  /*width: 80%;*/
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
