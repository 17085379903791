<template>
  <div>
    <Modal class="mobile-pop-login" footer-hide v-model="showLogin">
      <div class="pop-login-main">
        <div class="top">
          <div class="title">Welcome back</div>
        </div>
        <div class="email content-input">
          <div class="title">Email</div>
          <div class="input">
            <input
              placeholder="Enter your email"
              type="text"
              v-model="loginForm.email"
            />
          </div>
        </div>
        <div class="password content-input">
          <div class="title">Password</div>
          <div class="input">
            <input
              placeholder="Enter your password"
              type="password"
              v-model="loginForm.password"
            />
          </div>
        </div>
        <div class="forgot search-button" @click="forgetPassword()">
          Forgot password
        </div>

        <div
          :class="[
            'login-submit',
            'search-button',
            { 'login-submit-waiting': loginSubmitWaiting }
          ]"
          @click="login"
        >
          {{ signInText }}
        </div>
        <div class="register" @click="register">
          Not a member?
          <span class="search-button">Register here</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
// import api from "@/api";
// const { _queryAds, _hisAds } = api;

export default {
  props: [],
  data() {
    return {
      loginForm: { role: "", email: "", password: "" },
      showLogin: false,
      signInText: "Login",
      loginSubmitWaiting: false
    };
  },
  mounted() {},
  methods: {
    register() {
      this.$emit("register");
    },
    forgetPassword() {
      this.$router.push("/user-action/forgot-password");
    },
    changeRole(role) {
      this.loginForm.role = role;
    },
    login() {
      const {
        loginForm: { email, password, role }
      } = this;
      if (email == "" || password == "") {
        this.$Message.error("Please enter your email and password!");
        return;
      }
      this.loading = true;
      this.loginSubmitWaiting = true;
      this.signInText = "Logging in";
      this.$store
        .dispatch("user/login", {
          email,
          password,
          role
        })
        .then(res => {
          this.loginSubmitWaiting = false;
          this.signInText = "Login";
          console.log(res);
          this.$store.dispatch("chat/login");
          this.$Message.success("Login successful!");
          this.showLogin = false;
        })
        .catch(({ message }) => {
          this.signInText = "Login";
          this.loginSubmitWaiting = false;
          this.$Message.error(message);
        })
        .finally(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
.pop-login-main {
  /*height: 620px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 37px;
      display: flex;
      align-items: center;
      text-align: center;

      /* Black */

      color: #222222;
      margin-top: 5px;
    }
    .close {
    }
  }
  .desc {
    margin: 10px auto 18px auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    /* or 144% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
  }
  .type {
    .type-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 140% */

      display: flex;
      align-items: center;

      color: #000000;
      margin-bottom: 10px;
    }
    .types {
      /*display: flex;*/
      /*justify-content: space-between;*/
      width: 280px;
      .active-buyer {
        background: #f99d20;
        .buyer-name {
          color: #f2f2f2 !important;
        }
      }
      .buyer {
        display: flex;
        /*width: 230px;*/
        height: 45px;
        border: 1px solid #f57f45;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 15px;
        .buyer-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 15px;
        }
        .buyer-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;

          /* Black */

          color: #222222;

          /* Inside Auto Layout */

          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0px 16px;
        }
      }

      .active-seller {
        background: #f99d20;
        .seller-name {
          color: #f2f2f2 !important;
        }
      }
      .seller {
        display: flex;
        /*width: 230px;*/
        height: 45px;
        border: 1px solid #f57f45;
        box-sizing: border-box;
        border-radius: 8px;
        .seller-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 15px;
        }
        .seller-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;

          /* Black */

          color: #222222;

          /* Inside Auto Layout */

          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0px 16px;
        }
      }
    }
  }
  .content-input {
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 28px;
      /* identical to box height, or 140% */

      display: flex;
      align-items: center;

      color: #000000;
      margin: 20px auto 10px auto;
    }
    .input {
      input {
        width: 280px;
        height: 45px;
        left: 527px;
        top: 702px;

        /* Gray 6 */

        background: #f2f2f2;
        border-radius: 8px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        display: flex;
        align-items: center;

        /* Gray 3 */

        color: #828282;
        border: none;
        outline: none;
        padding-left: 10px;
      }
      input:focus {
        background: #fff;
        border: 1px solid #f57f45;
      }
    }
  }
  .forgot {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    /* or 162% */

    /*display: flex;*/
    align-items: center;
    /*justify-content: right;*/
    width: 280px !important;
    text-align: right;

    /* Gray 1 */

    color: #333333;
    margin: 16px auto;
  }
  .login-submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    width: 280px;
    height: 48px;
    background:#f57f45;
    /* Shadow */

    box-shadow: 4px 4px 20px rgba(34, 34, 34, 0.1);
    border-radius: 8px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    /* padding-top: 10px; */
    cursor: pointer;
    border: none;
  }
  .login-submit-waiting {
    opacity: 0.7;
  }
  .register {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    /* or 162% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
    margin-top: 30px;
    .register-here {
      color: #222222;
      font-weight: 600;
      margin-left: 5px;
    }
    span {
      font-weight: 600;
      font-size: 14px;
      color: #222;
      cursor: pointer;
      position: relative;
      bottom: 1px;
      left: 3px;
    }
  }
}
</style>

<style lang="less">
.mobile-pop-login {
  .ivu-modal {
    width: auto !important;
  }
}
</style>
